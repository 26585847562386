import React from 'react'
import { Container } from '../components/base'
import { LaunchDetails } from '../components/where/LaunchDetails'
import { withLayout } from '../components/Layout'

const WherePage = () => {
  return (
    <Container>
      <LaunchDetails />
    </Container>
  )
}

export default withLayout(WherePage)
