import addToMailchimp from 'gatsby-plugin-mailchimp'
import React, { useState } from 'react'
import {
  unstable_useFormState as useFormState,
  unstable_Form as Form,
  unstable_FormLabel as FormLabel,
  unstable_FormInput as FormInput,
  unstable_FormMessage as FormMessage,
  unstable_FormSubmitButton as FormSubmitButton,
} from 'reakit/Form'
import { Box, Flex, Text } from 'rebass/styled-components'

// export function MailChimp(): React.ReactElement {
//   const [email, setEmail] = useState('')

//   const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
//     e.preventDefault()

//     addToMailchimp(email).then(data => {
//       // alert(data.result)
//       console.log('DAATTTAAAA', data)
//     })
//     // .catch((error: Error) => {
//     //   // Errors in here are client side
//     //   // Mailchimp always returns a 200
//     // })
//   }

//   const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setEmail(event.currentTarget.value)
//   }

//   return (
//     <Flex
//       pb={4}
//       width={[1, 1, 1 / 3]}
//       sx={{ borderRadius: 30, textAlign: 'center', alignSelf: 'center' }}
//     >
//       <form onSubmit={handleSubmit}>
//         <Text color="primary.text">Email signup:&nbsp;</Text>
//         <div>
//           <input
//             placeholder="charlie@doe.com"
//             name="email"
//             type="text"
//             onChange={handleEmailChange}
//           />
//           <button type="submit">Subscribe</button>
//         </div>
//       </form>
//     </Flex>
//   )
// }
export function ChimpContainer1(): React.ReactElement {
  const form = useFormState({
    values: { email: '' },
    onValidate: values => {
      if (!values.email) {
        const errors = {
          email: 'How can we be friends without knowing your name?',
        }
        throw errors
      }
    },
    onSubmit: values => {
      // alert(JSON.stringify(values, null, 2))
      addToMailchimp(values).then(data => {
        // alert(data.result)
        // console.log('DAATTTAAAA', data)
      })
      // .catch((error: Error) => {
      //   // Errors in here are client side
      //   // Mailchimp always returns a 200
      // })
    },
    resetOnSubmitSucceed: true,
  })
  return (
    <Flex
      width={[6 / 10, 1, 1]}
      px={[0, 1, 1]}
      sx={{ borderRadius: 30, textAlign: 'center', justifyContent: 'center' }}
    >
      <Text
        color="primary.text"
        fontSize={[1, 2, 3]}
        fontFamily="body"
        mt={[-1, 0, 0]}
      >
        Email signup:&nbsp;
      </Text>
      <Form {...form}>
        <FormLabel {...form} name="email" />
        <FormInput {...form} name="email" placeholder="charlie@doe.com" />
        <FormMessage {...form} name="email" />
        <Box ml={[5, 0, 0]}>
          <FormSubmitButton {...form}>Submit</FormSubmitButton>
        </Box>
      </Form>
    </Flex>
  )
}
