import React from 'react'
import { Box, Text } from 'rebass/styled-components'
import { ChimpContainer1 } from './MailChimp'
import {
  ExternalLinkWhite,
  SectionBox,
  SeparatorLine,
  Subtitle,
  HeaderTitle,
} from '../base'

export function LaunchDetails(): React.ReactElement {
  return (
    <SectionBox bg="primary.main">
      <HeaderTitle
        pb={[4, 4, 4]}
        pt={[0, 5, 5]}
        color="primary.text"
        textAlign="center"
        fontSize={[8, 9, 14]}
        fontWeight="black"
      >
        Launch details coming soon.
      </HeaderTitle>
      <Box
        px={[2, 3, 6]}
        mx={[0, 3, 0]}
        pb={[4, 4, 5]}
        color="primary.text"
        fontSize={[0, 1, 5]}
      >
        <Text color="primary.text" fontFamily="body">
          {/* You can use Choice now at our pilot merchant,&nbsp;
          <ExternalLinkWhite href="https://www.google.co.nz/maps?q=peoples+coffee+lukes+lane&um=1&ie=UTF-8&sa=X&ved=0ahUKEwiXtvqUzI3kAhWOfH0KHUyBBdoQ_AUIESgB">
            <Text
              sx={{
                color: 'primary.text',
                textDecoration: 'underline',
                display: 'inline-block',
              }}
            >
              Peoples Coffee Lukes Lane&nbsp;
            </Text>
          </ExternalLinkWhite>
          in Wellington.  */}
          Follow our journey and get the latest launch updates by signing up to
          our email list below!
        </Text>
      </Box>
      <ChimpContainer1 />
      <Subtitle fontSize={4} p={4} textAlign="center">
        Are you a merchant?
      </Subtitle>
      <SeparatorLine color="white" />
    </SectionBox>
  )
}
